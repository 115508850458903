import React from 'react';
import styled from 'styled-components';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';
import People from '@datasources/People';
import PeoplePictureLib from '@datasources/HexaPeoplePictures';


const MiniCardItem = ({ number, left }) => {
  return (<MiniCard left={left}>
    <img src={PeoplePictureLib[number]?PeoplePictureLib[number]:null} alt={PeoplePictureLib[number]?PeoplePictureLib[number]:null} />
    <TextZone>
      <h2>{People["data"][number].name?People["data"][number].name:null}</h2>
      <h3>{People["data"][number].description?People["data"][number].description:null}</h3>
      <h3>{People["data"][number].country?People["data"][number].country:null}</h3>
    </TextZone>
  </MiniCard>
  )
}



const IndexPage = () => {


  return (
    <TemplatePage1
      title={"Technical assesment Comitee(CAT)"}
      tc={"white"}
      description={"Goal 1: Establish as the Quest LAC Network: Technical Assesment Comitee (CAT)"}
      bg={"var(--tema4)"}
      hero={true}
      content={(
        <Wrapper>
          <CardWrapper>
          <MiniCardItem number={14} left/>
          <MiniCardItem number={0}/>
          <MiniCardItem number={15} left/>
          <MiniCardItem number={1}/>
          </CardWrapper>
        </Wrapper>
      )}
    />
  )
};

export default IndexPage

const Wrapper = styled.div`
max-width: 100%;
display: flex;
flex-wrap: nowrap;
flex-direction: column;
justify-content: center;
align-items: flex-end;
`;



const Hexagon = styled.div`
background-color:var(--tema4);
position: relative;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-size: contain;
background-repeat: no-repeat;
background-position-x:50%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
height: 100px;
min-height:100px;
max-height:100px;
min-width:100px;
width: 100px;
max-width:100px;


@media only screen and (min-width:640px){
  height: 120px;
min-height:120px;
max-height:120px;
min-width:120px;
width: 120px;
max-width:120px;
}


@media only screen and (min-width:768px){
  height: 200px;
min-height:200px;
max-height:200px;
min-width:200px;
width: 200px;
max-width:200px;
}





background-image: url(${props => props.bg ? props.bg : ""});
`

const MiniCard = styled.div`
margin: 10px 10px;
max-width: 500px;
display: flex;
flex-direction: ${props=>props.left?"row":"row-reverse"};
flex-wrap:nowrap;
align-items: center;
justify-content: center;
float: ${props=>props.left?"left":"right"};
text-align: ${props=>props.left?"left":"right"};
`



const TextZone = styled.div`
color: white;
Margin: 10px 10px;
h2{
  font-size:18px;
  font-weight:bold;
}
h3{
  font-size:14px;
}

@media only screen and (min-width:640px){
  h2{
  font-size:20px;
  font-weight:bold;
}
h3{
  font-size:18px;
} 
}


`
const CardWrapper = styled.div`
max-width: 100%;
`