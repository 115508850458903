const People = {
  data: [
    {
      id: 0,
      name: "MD, MSc., DSc. Svetlana Doubova",
      title: "",
      description:
        "Director of the Unit of Epidemics Research and Health Services of the Institute of social security of Mexico",
    },
    {
      id: 1,
      name: "Dra. Hannah Leslie",
      title: "",
      description:
        "Assistant Professor of California University and member of the Lancet Comission of High Quality Health Systems.",
    },
    {
      id: 2,
      name: "Dr. Ezequiel García Elorrio",
      title: "",
      description:
        "Co-Director of the QuEST LAC network and Director of the Institute of Clinical and Sanitarian Effectiveness.",
    },
    {
      id: 3,
      name: "Teresa Tono",
      title: "",
      description:
        "Director of the Organization for excelence of health (OES) and the Center of Hospital Management (CGH).",
      country: "Colombia",
    },
    {
      id: 4,
      name: "Adolfo Rubinstein",
      title:
        "CIIPS Director - Center for implementation and innovation of Health Policies from IECS",
      description:
        "CIIPS Director - Center for implementation and innovation of Health Policies from IECS",
      country: "Argentina",
    },
    {
      id: 5,
      name: "María del Carmen Calle",
      title:
        "Excecutive Secretary from the Andean Organization of Health under Hipolito Unanue agreement.",
      description:
        "Excecutive Secretary from the Andean Organization of Health under Hipolito Unanue agreement.",
      country: "Perú",
    },
    {
      id: 6,
      name: "María del Rocío Saénz",
      title: "",
      description:
        "Ex Health Minister from Costa Rica and Ex president of the Costa Rican Social Security Funds.",
      country: "Costa Rica",
    },
    {
      id: 7,
      name: "Enrique Ruelas",
      description:
        "Member of the Directive council and Fellow Senior from the Institute for Health Improve of the United States(IHI)",
      country: "Mexico",
    },
    {
      id: 8,
      name: "Dr. Jeremy Veillard",
      description:
        "Superior Specialist in Health for LAtin America and the Caribbean from the World Bank",
      country: "Colombia",
    },
    {
      id: 9,
      name: "Frederico Guanais",
      description:
        "Chief of the Health Unit from the Organization for Cooperation and Economic Development (OCDE)",
      country: "Brasil",
    },
    {
      id: 10,
      name: "Ana María Malik",
      description:
        "Member of the group GENiE (Global Educators Network for HealthCare Innovation Education)",
      country: "Brasil",
    },
    {
      id: 11,
      name: "Barbara Profeta",
      description:
        "Assesor of the Health Unit for West Europe and Asian from the Organization for Cooperation and Economic Development (OCDE)",
      country: "Suiza",
    },
    {
      id: 12,
      name: "Rosa Suñol",
      description: "Presidenta of the Board for the Avedis Donabedian Fund",
      country: "España",
    },
    {
      id: 13,
      name: "Jafet Arrieta",
      description:
        "Senior Directorof the Institue for the Institute of Health Improvement of the United States (IHI)",
      country: "USA",
    },
    {
      id: 14,
      name: "Margaret Kruk",
      description:
        "Director of the Global QuEST network and Principal Professor of the Public Health Facultee from Harvard T.H.",
      country: "USA",
    },
    {
      id: 15,
      name: "Sanam Roger Dewan",
      description:
        "Superior Specialist in Health and Innovation for the World Bank Services",
    },
    {
      id: 16,
      name: "Dr. Jorge Hermilda",
      description:
        "President of the Foundation for Research and Health Service Management",
    },
    {
      id: 17,
      name: "Dra. Agustina Mazzoni",
      description: "Researcher of the QuEST LAC Network and IECS",
    },
    {
      id: 18,
      name: "Dra. Patricia Jimenez López",
      description:
        "Representative of the Andean Organization for Health -Hipolito Unanue Agreement ORAS-CONHU",
    },
    {
      id: 19,
      name: "Dra. Catherine Arsenault",
      description:
        "Assistant Proffessor of the Global Health Department of the George Washington University",
    },
    {
      id: 20,
      name: "Dr. Jesús Medina Ranilla",
      description:
        "Researcher of the QuEST LAC network from Cayetano Heredia University",
    },
    {
      id: 21,
      name: "Laura Espinoza Pajuelo",
      description:
        "Junior Researcher from Cayetano Heredia University",
    },
    {
      id: 22,
      name: "Dra. Hortensia Reyes Morales",
      description:
        "Director of the research center of Health Systems from INSP",
    },
    {
      id: 23,
      name: "Dra. Blanca Pelcastre Villafuerte",
      description: "Director from Area of Equity in Health from INSP",
    },
    {
      id: 24,
      name: "Dra. Ileana Heredia Pi",
      description: "Researcher of Medical Science from INSP",
    },
    {
      id: 25,
      name: "Dra. Anahí Dreser Mansilla",
      description: "Researcher of Medical Science from INSP",
    },
    {
      id: 26,
      name: "Dra. Patricia García Funegra",
      description: "Principal Researcher of the QuEST LAC network from Cayetano Heredia University",
    },
    {
      id: 27,
      name: "PhD. Renzo Calderon-Anyosa",
      description: "Post Doc Researcher of the University of Toronto from Cayetano Heredia University",
    },
    {
      id: 28,
      name: "PhD. Javier Roberti",
      description: "Researcher of the QuEST LAC network from the Institute of Clinical and Sanitarian Effectiveness",
    },
  ],
};

export default People;

/**{   
            "id": 1,
            "title":"curso 1",
            "date":"Fecha 1",
            "time":"Hora 1",
            "image":"vacio",
            "description":"Es un evento tipo curso pero es numero 1",
            "link":"/",
        }
**/
